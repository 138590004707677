import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusCodes } from 'http-status-codes';
import { FeatureService } from 'services/feature.service';
import { MessageService } from 'services/message.service';
import { UtilsService } from 'services/utils.service';
import { WindowService } from 'services/window.service';
import { TenantFeatures } from 'types/Tenant';
import { Utils } from 'utils/Utils';

import pkg from '../../../package.json';
import { RouteGuardService } from '../guard/route-guard';
import { CentralServerService } from '../services/central-server.service';
import { User, UserToken } from '../types/User';
import { Constants } from '../utils/Constants';

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent {
  public version: string = pkg.version;
  public appName: string = Constants.APP_NAME;
  public mobileMenuVisible: any = 0;
  public menuItems!: any[];
  public loggedUser!: UserToken;
  public user!: User;
  public userImage = Constants.USER_NO_PICTURE;
  public canUpdateUser = false;
  public logo = Constants.NO_IMAGE;
  public sidebarMinimized: boolean;
  public isPrepaidBillingFeatureActive: boolean;
  public isPrepaidBillingActive: boolean;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private guard: RouteGuardService,
    private utilsService: UtilsService,
    private windowService: WindowService,
    private featureService: FeatureService,
    private centralServerService: CentralServerService,
    private messageService: MessageService,
    @Inject(DOCUMENT) private document: Document) {
    // Get the routes
    if (this.activatedRoute && this.activatedRoute.routeConfig && this.activatedRoute.routeConfig.children) {
      this.menuItems = this.activatedRoute.routeConfig.children.filter((route) =>
        route.data && route.data.menu && this.guard.isRouteAllowed(route) &&
        this.guard.canLoad(route, [])).map((route) => route && route.data ? route.data.menu : null);
    }
    this.isPrepaidBillingFeatureActive = this.featureService.isActive(TenantFeatures.PREPAID_BILLING);
    this.loggedUser = this.centralServerService.getLoggedUser();
    // Read user
    this.loadUser();
    // Read tenant
    this.loadTenantLogo();
  }

  public toggleSidebar() {
    const body = this.document.getElementsByTagName('body')[0];
    if (this.sidebarMinimized === true) {
      body.classList.remove('sidebar-mini');
      this.sidebarMinimized = false;
    } else {
      body.classList.add('sidebar-mini');
      this.sidebarMinimized = true;
    }
  }

  public logout() {
    this.centralServerService.logout().subscribe({
      next: () => {
        this.centralServerService.clearLoginInformation();
        void this.router.navigate(['/auth/login']);
      },
      error: (error) => {
        this.centralServerService.clearLoginInformation();
        void this.router.navigate(['/auth/login']);
      }
    });
  }

  public loadUser() {
    this.centralServerService.getUser(this.loggedUser.id).subscribe((user) => {
      if (user) {
        this.user = user;
        this.canUpdateUser = user.canUpdate;
        this.loadBillingSettings();
        this.loadUserImage();
      }
    });
  }

  private loadUserImage() {
    this.centralServerService.getUserImage(this.user.id).subscribe({
      next: (userImage) => {
        this.userImage = userImage ?? Constants.USER_NO_PICTURE;
      },
      error: (error) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            this.userImage = Constants.USER_NO_PICTURE;
            break;
          default:
            Utils.handleHttpError(error, this.router, this.messageService,
              this.centralServerService, 'general.unexpected_error_backend');
        }
      }
    });
  }

  private loadBillingSettings() {
    if (this.isPrepaidBillingFeatureActive) {
      this.centralServerService.getBillingSettings().subscribe({
        next: (billingSettings) => {
          this.isPrepaidBillingActive = Utils.isPrepaidBillingActive(billingSettings, this.user);
        },
        error: (error) => {
          switch (error.status) {
            default:
              Utils.handleHttpError(error, this.router, this.messageService,
                this.centralServerService, 'general.unexpected_error_backend');
          }
        }
      });
    }
  }

  private loadTenantLogo() {
    this.utilsService.getTenantLogoBySubdomain(this.windowService.getSubdomain()).subscribe({
      next: (tenantLogo: string) => {
        this.logo = tenantLogo;
      }
    });
  }
}
