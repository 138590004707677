import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Unit } from 'types/Unit';

import { AppDecimalPipe } from './app-decimal.pipe';

@Pipe({ name: 'appUnit' })
export class AppUnitPipe implements PipeTransform {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    public translateService: TranslateService,
    private appDecimalPipe: AppDecimalPipe,
  ) {}

  public transform(value: number, srcMeasure: Unit, destMeasure: Unit, withUnit: boolean = true, numberOfInteger: number = 1,
      numberOfDecimalMin: number = 2, numberOfDecimalMax: number = 2): string {
    if (value === 0) {
      numberOfDecimalMin = 0;
    }
    if (srcMeasure === destMeasure) {
      return this.appDecimalPipe.transform(value,
        `${numberOfInteger}.${numberOfDecimalMin}-${numberOfDecimalMax}`) + `${withUnit ? ` ${this.translateService.instant(`units.${destMeasure}`)}` : ''}`;
    }
    const src = this.parseMeasure(this.translateService.instant(`units.${srcMeasure}`));
    const dest = this.parseMeasure(this.translateService.instant(`units.${destMeasure}`));
    return this.appDecimalPipe.transform(
      value / (src.size * dest.size), `${numberOfInteger}.${numberOfDecimalMin}-${numberOfDecimalMax}`) + `${withUnit ? ` ${this.translateService.instant(`units.${destMeasure}`)}` : ''}`;
  }

  private parseMeasure(measureAsString: Unit): Measure {
    if (InternalUnit[InternalUnit[measureAsString]] === measureAsString) {
      return { unit: InternalUnit[measureAsString], size: Size.BASIS };
    }
    return {
      unit: InternalUnit[measureAsString.slice(1)],
      size: Size[measureAsString.slice(0, 1).toUpperCase()] as any,
    };
  }
}

interface Measure {
  unit: string;
  size: number;
}

enum InternalUnit {
  W,
  Wh,
}
enum Size {
  BASIS = 1,
  C = 10 * Size.BASIS,
  D = 10 * Size.C,
  K = 10 * Size.D,
  M = 10 * Size.K,
}
