import 'chartjs-adapter-date-fns';

import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { CentralServerService } from 'services/central-server.service';
import { ComponentService } from 'services/component.service';
import { WindowService } from 'services/window.service';
import { Constants } from 'utils/Constants';

import { CONNECTOR_ALL_TYPES_MAP } from './shared/model/charging-stations.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public title = Constants.APP_NAME;

  public constructor(
    private iconRegistry: MatIconRegistry,
    private centralServerService: CentralServerService,
    private windowService: WindowService,
    private componentService: ComponentService,
    private sanitizer: DomSanitizer) {
    for (const connectorMap of CONNECTOR_ALL_TYPES_MAP) {
      if (connectorMap.svgIconName) {
        this.iconRegistry.addSvgIcon(
          connectorMap.svgIconName,
          this.sanitizer.bypassSecurityTrustResourceUrl(connectorMap.svgIconFile));
      }
    }
    Chart.register(...registerables);
    Chart.register(zoomPlugin);
    this.initTenantPublicInfo();
  }

  private initTenantPublicInfo() {
    const subDomain = this.windowService.getSubdomain();
    if (subDomain) {
      this.centralServerService.getTenantPublicInfo(subDomain).subscribe({
        next: (tenantPublicInfo) => {
          if (tenantPublicInfo) {
            this.componentService.setTenantPublicInfo(tenantPublicInfo);
          }
        }
      });
    }
  }
}
