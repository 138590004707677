
export enum Unit {
  KILO_WATT = 'kilo_watt',
  KILO_WATT_HOUR = 'kilo_watt_hour',
  WATT = 'watt',
  WATT_HOUR = 'watt_hour',
  VOLT = 'volt',
  AMP = 'amp',
  AMP_HOUR = 'amp_hour',
}
