import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { StatusCodes } from 'http-status-codes';
import { Observable, Observer } from 'rxjs';
import { BillingSettings } from 'types/Setting';
import { Utils } from 'utils/Utils';

import { CentralServerService } from './central-server.service';
import { ComponentService } from './component.service';
import { MessageService } from './message.service';

@Injectable()
export class StripeService {
  private static stripeFacade: Stripe;

  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private centralServerService: CentralServerService,
    private componentService: ComponentService,
    private messageService: MessageService,
    private router: Router) {
  }

  public initStripe(): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      if (!StripeService.stripeFacade) {
        // Load settings
        this.componentService.getBillingSettings().subscribe({
          next: async (billingSettings: BillingSettings) => {
            if (billingSettings?.stripe?.publicKey) {
              loadStripe.setLoadParameters({ advancedFraudSignals: false });
              StripeService.stripeFacade = await loadStripe(billingSettings.stripe.publicKey);
            }
            observer.complete();
          },
          error: (error) => {
            switch (error.status) {
              case StatusCodes.NOT_FOUND:
                this.messageService.showErrorMessage('settings.billing.not_found');
                break;
              default:
                Utils.handleHttpError(error, this.router, this.messageService,
                  this.centralServerService, 'general.unexpected_error_backend');
            }
            observer.error(error);
          }
        });
      } else {
        observer.complete();
      }
    });
  }

  public getStripeFacade() {
    return StripeService.stripeFacade;
  }
}
