import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormattersModule } from 'shared/formatters/formatters.module';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    FormattersModule,
    MatIconModule,
  ],
  declarations: [
    SidebarComponent,
  ],
  exports: [
    SidebarComponent,
  ],
})
export class SidebarModule {
}
