import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BillingTax } from '../../types/Billing';

@Pipe({ name: 'appTaxName' })
export class AppTaxName implements PipeTransform {
  public constructor(
    private translateService: TranslateService,
  ) {}

  public transform(tax: BillingTax): string {
    if (!tax) {
      return '';
    }

    let formatted = '';
    formatted += tax.displayName ? tax.displayName + ' - ' : '';
    formatted += tax.description ? tax.description + ' - ' : '';
    formatted += tax.percentage ? '(' + tax.percentage + this.translateService.instant('units.percent') + ')' : '';
    return formatted;
  }
}
