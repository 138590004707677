import { Pipe, PipeTransform } from '@angular/core';
import cronParser  from 'cron-parser';
import cronstrue from 'cronstrue/i18n';

import { LocaleService } from '../../services/locale.service';

@Pipe({ name: 'appCronToStringPipe' })
export class AppCronToStringPipe implements PipeTransform {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private localeService: LocaleService
  ) {}

  public transform(periodicity: string): string | null {
    let isCronValid = true;
    try {
      cronParser.parseExpression(periodicity)
    } catch(error) {
      isCronValid = false;
      return '-'
    }
    const language = this.localeService.getLocaleInformation()?.language;
    return cronstrue.toString(periodicity, { locale: language });
  }
}
