import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingAccount } from 'types/Billing';
import { Constants } from 'utils/Constants';

import { ActionResponse, Ordering, Paging } from '../types/DataResult';
import { AnalyticsSettings, AnalyticsSettingsType, AssetConnectionType, AssetSettings, AssetSettingsType, BillingSettings, BillingSettingsType, CarConnectorConnectionType, CarConnectorSettings, CarConnectorSettingsType, CarSettings, CarSettingsType, CryptoSettings, CryptoSettingsType, GridMonitoringConnectionType, GridMonitoringSettings, GridMonitoringSettingsType, PlugAndChargeConnectionType, PlugAndChargeSettings, PlugAndChargeSettingsType, PricingSettings, PricingSettingsType, RefundSettings, RefundSettingsType, RoamingSettings, RoamingSettingsType, SmartChargingSettings, SmartChargingSettingsType, TechnicalSettings, UserSettings, UserSettingsType } from '../types/Setting';
import { TenantComponents, TenantPublicInfo } from '../types/Tenant';
import { Utils } from '../utils/Utils';
import { CentralServerService } from './central-server.service';

@Injectable()
export class ComponentService {
  private activeComponents!: string[];
  private tenantPublicInfo: TenantPublicInfo;

  public constructor(
    private centralServerService: CentralServerService) {
    this.centralServerService.getCurrentUserSubject().subscribe((user) => {
      this.activeComponents = user?.activeComponents;
    });
  }

  public setTenantPublicInfo(tenantPublicInfo: TenantPublicInfo) {
    this.tenantPublicInfo = tenantPublicInfo;
  }

  public getTenantPublicInfo(): TenantPublicInfo {
    return this.tenantPublicInfo;
  }

  public isActive(componentName: TenantComponents): boolean {
    if (this.activeComponents) {
      return this.activeComponents.includes(componentName);
    }
    return false;
  }

  public getActiveComponents(): string[] {
    return this.activeComponents;
  }

  public getPricingSettings(): Observable<PricingSettings> {
    return new Observable((observer) => {
      const pricingSettings = {
        identifier: TenantComponents.PRICING,
      } as PricingSettings;
      // Get the Pricing settings
      this.centralServerService.getSetting(TenantComponents.PRICING).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            // ID
            pricingSettings.id = settings.id;
            pricingSettings.sensitiveData = settings.sensitiveData;
            // Simple price
            if (config.simple) {
              pricingSettings.type = PricingSettingsType.SIMPLE;
              pricingSettings.simple = {
                price: config.simple.price ? Utils.convertToFloat(config.simple.price) : 0,
                currency: config.simple.currency ? config.simple.currency : '',
              };
            }
          }
          observer.next(pricingSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public savePricingSettings(settings: PricingSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.PRICING,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveBillingSettings(settings: BillingSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = BillingSettingsType.STRIPE;
    }
    // Save
    return this.centralServerService.updateBillingSettings(settings);
  }

  public saveRefundSettings(settings: RefundSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = RefundSettingsType.CONCUR;
    }
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.REFUND,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    if (settings.type === RefundSettingsType.CONCUR) {
      settingsToSave.sensitiveData = ['content.concur.clientSecret'];
    }
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveOcpiSettings(settings: RoamingSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.OCPI,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveOicpSettings(settings: RoamingSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.OICP,
      content: Utils.cloneObject(settings),
      sensitiveData: ['content.oicp.cpo.key', 'content.oicp.cpo.cert', 'content.oicp.cpo.hubjectCert', 'content.oicp.emsp.key', 'content.oicp.emsp.cert']
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveAssetConnectionSettings(settings: AssetSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = AssetSettingsType.ASSET;
    }
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.ASSET,
      sensitiveData: [],
      content: Utils.cloneObject(settings) as AssetSettings,
    };
    settingsToSave.content.asset.connections.forEach((settingConnection, index) => {
      switch (settingConnection.type) {
        case AssetConnectionType.SCHNEIDER:
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].schneiderConnection.password`);
          break;
        case AssetConnectionType.GREENCOM:
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].greencomConnection.clientSecret`);
          break;
        case AssetConnectionType.IOTHINK:
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].iothinkConnection.password`);
          break;
        case AssetConnectionType.WIT:
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].witConnection.password`);
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].witConnection.clientSecret`);
          break;
        case AssetConnectionType.LACROIX:
          settingsToSave.sensitiveData.push(`content.asset.connections[${index}].lacroixConnection.password`);
          break;
      }

    });
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveCarConnectorConnectionSettings(settings: CarConnectorSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = CarConnectorSettingsType.CAR_CONNECTOR;
    }
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.CAR_CONNECTOR,
      sensitiveData: [],
      content: Utils.cloneObject(settings) as CarConnectorSettings,
    };
    settingsToSave.content.carConnector.connections.forEach((settingConnection, index) => {
      switch (settingConnection.type) {
        case CarConnectorConnectionType.MERCEDES:
          settingsToSave.sensitiveData.push(`content.carConnector.connections[${index}].mercedesConnection.clientSecret`);
          break;
        case CarConnectorConnectionType.TRONITY:
          settingsToSave.sensitiveData.push(`content.carConnector.connections[${index}].tronityConnection.clientSecret`);
          break;
        case CarConnectorConnectionType.TARGA_TELEMATICS:
          settingsToSave.sensitiveData.push(`content.carConnector.connections[${index}].targaTelematicsConnection.clientSecret`);
          break;
      }
    });
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveGridComponentConnectionSettings(settings: GridMonitoringSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = GridMonitoringSettingsType.GRID_MONITORING;
    }
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.GRID_MONITORING,
      sensitiveData: [],
      content: Utils.cloneObject(settings) as GridMonitoringSettings,
    };
    settingsToSave.content.gridMonitoring.connections.forEach((settingConnection, index) => {
      switch (settingConnection.type) {
        case GridMonitoringConnectionType.ECOWATT:
          settingsToSave.sensitiveData.push(`content.gridMonitoring.connections[${index}].ecowattConnection.clientSecret`);
          break;
      }
    });
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveSacSettings(settings: AnalyticsSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.ANALYTICS,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveSmartChargingSettings(settings: SmartChargingSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.SMART_CHARGING,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    if (settings.type === SmartChargingSettingsType.SAP_SMART_CHARGING) {
      settingsToSave.sensitiveData = ['content.sapSmartCharging.password'];
    }
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public getBillingSettings(): Observable<BillingSettings> {
    return new Observable((observer) => {
      // Get the Billing settings
      this.centralServerService.getBillingSettings().subscribe({
        next: (billingSettings) => {
          observer.next(billingSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getBillingAccounts(paging: Paging = Constants.DEFAULT_PAGING,
    ordering: Ordering[] = []): Observable<BillingAccount[]> {
    return new Observable((observer) => {
      this.centralServerService.getBillingAccounts({}, paging, ordering).subscribe({
        next: (accounts) => {
          observer.next(accounts.result);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getOcpiSettings(): Observable<RoamingSettings> {
    return new Observable((observer) => {
      const ocpiSettings = {
        identifier: TenantComponents.OCPI,
      } as RoamingSettings;
      // Get the Pricing settings
      this.centralServerService.getSetting(TenantComponents.OCPI).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            ocpiSettings.id = settings.id;
            ocpiSettings.type = config.type as RoamingSettingsType;
            ocpiSettings.sensitiveData = settings.sensitiveData;
            ocpiSettings.ocpi = config.ocpi;
          }
          observer.next(ocpiSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getOicpSettings(): Observable<RoamingSettings> {
    return new Observable((observer) => {
      const oicpSettings = {
        identifier: TenantComponents.OICP,
      } as RoamingSettings;
      // Get the Pricing settings
      this.centralServerService.getSetting(TenantComponents.OICP).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            oicpSettings.id = settings.id;
            oicpSettings.type = config.type as RoamingSettingsType;
            oicpSettings.sensitiveData = settings.sensitiveData;
            oicpSettings.oicp = config.oicp;
          }
          observer.next(oicpSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getSacSettings(): Observable<AnalyticsSettings> {
    return new Observable((observer) => {
      const analyticsSettings = {
        identifier: TenantComponents.ANALYTICS,
      } as AnalyticsSettings;
      // Get the Pricing settings
      this.centralServerService.getSetting(TenantComponents.ANALYTICS).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            analyticsSettings.id = settings.id;
            analyticsSettings.type = config.type as AnalyticsSettingsType;
            analyticsSettings.sensitiveData = settings.sensitiveData;
            analyticsSettings.sac = config.sac;
            analyticsSettings.links = config.links;
          }
          observer.next(analyticsSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getRefundSettings(): Observable<RefundSettings> {
    return new Observable((observer) => {
      const refundSettings = {
        identifier: TenantComponents.REFUND,
      } as RefundSettings;
      // Get the Pricing settings
      this.centralServerService.getSetting(TenantComponents.REFUND).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            refundSettings.id = settings.id;
            refundSettings.type = config.type as RefundSettingsType;
            refundSettings.sensitiveData = settings.sensitiveData;
            refundSettings.concur = config.concur;
          }
          observer.next(refundSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getSmartChargingSettings(): Observable<SmartChargingSettings> {
    return new Observable((observer) => {
      let smartChargingSettings = {
        identifier: TenantComponents.SMART_CHARGING,
      } as SmartChargingSettings;
      // Get the SmartCharging settings
      this.centralServerService.getSetting(TenantComponents.SMART_CHARGING).subscribe({
        next: (settings) => {
          if (settings) {
            const config = settings.content;
            smartChargingSettings = settings as SmartChargingSettings;
            smartChargingSettings.type = config.type as SmartChargingSettingsType;
            smartChargingSettings.sapSmartCharging = config.sapSmartCharging;
          }
          observer.next(smartChargingSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getAssetSettings(): Observable<AssetSettings> {
    return new Observable((observer) => {
      let assetSettings = {
        identifier: TenantComponents.ASSET,
      } as AssetSettings;
      // Get the Asset settings
      this.centralServerService.getSetting(TenantComponents.ASSET).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            assetSettings = settings as AssetSettings;
            assetSettings.type = config.type as AssetSettingsType;
            assetSettings.asset = config.asset;
          }
          observer.next(assetSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getCarConnectorSettings(): Observable<CarConnectorSettings> {
    return new Observable((observer) => {
      const carConnectorsSettings = {
        identifier: TenantComponents.CAR_CONNECTOR,
      } as CarConnectorSettings;
      // Get the Car Connector settings
      this.centralServerService.getSetting(TenantComponents.CAR_CONNECTOR).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            carConnectorsSettings.id = settings.id;
            carConnectorsSettings.type = config.type as CarConnectorSettingsType;
            carConnectorsSettings.sensitiveData = settings.sensitiveData;
            carConnectorsSettings.carConnector = config.carConnector;
          }
          observer.next(carConnectorsSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getCarSettings(): Observable<CarSettings> {
    return new Observable((observer) => {
      const carSettings = {
        identifier: TenantComponents.CAR,
      } as CarSettings;
      // Get the Car Connector settings
      this.centralServerService.getSetting(TenantComponents.CAR).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            carSettings.id = settings.id;
            carSettings.type = config.type as CarSettingsType;
            carSettings.sensitiveData = settings.sensitiveData;
            carSettings.car = config.car;
          }
          observer.next(carSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getGridMonitoringSettings(): Observable<GridMonitoringSettings> {
    return new Observable((observer) => {
      const gridMonitoringSettings = {
        identifier: TenantComponents.GRID_MONITORING,
      } as GridMonitoringSettings;
      // Get the Car Connector settings
      this.centralServerService.getSetting(TenantComponents.GRID_MONITORING).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            gridMonitoringSettings.id = settings.id;
            gridMonitoringSettings.type = config.type as GridMonitoringSettingsType;
            gridMonitoringSettings.sensitiveData = settings.sensitiveData;
            gridMonitoringSettings.gridMonitoring = config.gridMonitoring;
          }
          observer.next(gridMonitoringSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public getPlugAndChargeSettings(): Observable<PlugAndChargeSettings> {
    return new Observable((observer) => {
      let plugAndChargeSettings = {
        identifier: TenantComponents.PLUG_AND_CHARGE,
      } as PlugAndChargeSettings;
      // Get the Plug And Charge settings
      this.centralServerService.getSetting(TenantComponents.PLUG_AND_CHARGE).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            plugAndChargeSettings = settings as PlugAndChargeSettings;
            plugAndChargeSettings.type = config.type as PlugAndChargeSettingsType;
            plugAndChargeSettings.plugAndCharge = config.plugAndCharge;
          }
          observer.next(plugAndChargeSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public savePlugAndChargeConnectionSettings(settings: PlugAndChargeSettings): Observable<ActionResponse> {
    // Check the type
    if (!settings.type) {
      settings.type = PlugAndChargeSettingsType.PLUG_AND_CHARGE;
    }
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TenantComponents.PLUG_AND_CHARGE,
      sensitiveData: [],
      content: Utils.cloneObject(settings) as PlugAndChargeSettings,
    };
    settingsToSave.content.plugAndCharge.connections.forEach((settingConnection, index) => {
      switch (settingConnection.type) {
        case PlugAndChargeConnectionType.HUBJECT:
          settingsToSave.sensitiveData.push(`content.plugAndCharge.connections[${index}].hubjectConnection.clientSecret`);
          break;
      }
    });
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    // Save
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public getCryptoSettings(): Observable<CryptoSettings> {
    return new Observable((observer) => {
      const cryptoSettings = {
        identifier: TechnicalSettings.CRYPTO,
      } as CryptoSettings;
      // Get the Asset settings
      this.centralServerService.getSetting(TechnicalSettings.CRYPTO).subscribe({
        next: (settings) => {
          // Get the currency
          if (settings) {
            const config = settings.content;
            cryptoSettings.id = settings.id;
            cryptoSettings.type = config.type as CryptoSettingsType;
            cryptoSettings.crypto = {
              key: settings.content.crypto.key,
              keyProperties: settings.content.crypto.keyProperties,
              migrationToBeDone: settings.content.crypto.migrationToBeDone,
            };
          }
          observer.next(cryptoSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public saveCryptoSettings(settings: CryptoSettings): Observable<ActionResponse> {
    // Build setting payload
    const settingsToSave = {
      id: settings.id,
      identifier: TechnicalSettings.CRYPTO,
      sensitiveData: [],
      content: Utils.cloneObject(settings),
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public getUserSettings(): Observable<UserSettings> {
    return new Observable((observer) => {
      // Get the user settings
      this.centralServerService.getSetting(TechnicalSettings.USER).subscribe({
        next: (settings) => {
          let userSettings: UserSettings;
          // Get the needed settings for update
          if (settings) {
            userSettings = {
              id: settings.id,
              identifier: TechnicalSettings.USER,
              type: settings.content.type as UserSettingsType,
              user: settings.content.user,
            };
          }
          observer.next(userSettings);
          observer.complete();
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  public saveUserSettings(settings: UserSettings): Observable<ActionResponse> {
    // build settings to proceed update
    const settingsToSave = {
      id: settings.id,
      identifier: settings.identifier,
      content: Utils.cloneObject(settings)
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    return this.centralServerService.updateSetting(settingsToSave);
  }

  public saveCarSettings(settings: CarSettings): Observable<ActionResponse> {
    // build settings to proceed update
    const settingsToSave = {
      id: settings.id,
      identifier: settings.identifier,
      content: Utils.cloneObject(settings)
    };
    // Delete IDS
    delete settingsToSave.content.id;
    delete settingsToSave.content.identifier;
    delete settingsToSave.content.sensitiveData;
    return this.centralServerService.updateSetting(settingsToSave);
  }
}
