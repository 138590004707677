import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BillingInvoiceType } from 'types/Billing';

@Pipe({ name: 'appInvoiceType' })
export class AppInvoiceTypePipe implements PipeTransform {
  public constructor(
    public translateService: TranslateService) {
  }

  public transform(invoiceType: BillingInvoiceType): string {
    switch (invoiceType) {
      case BillingInvoiceType.TRANSACTION:
        return this.translateService.instant('invoices.type_transaction');
      case BillingInvoiceType.USER_SUBSCRIPTION:
        return this.translateService.instant('invoices.type_subscription');
    }
  }
}
