import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LandscapeType } from 'types/configuration/Landscape';
import { Constants } from 'utils/Constants';

import { CentralServerService } from './central-server.service';
import { ConfigService } from './config.service';
import { SpinnerService } from './spinner.service';

@Injectable()
export class UtilsService {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private configService: ConfigService,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
  ) {}

  public getTenantLogoBySubdomain(subdomain: string) {
    return new Observable<string>((observer) => {
      if (subdomain) {
        // Retrieve tenant's logo
        this.spinnerService.show();
        this.centralServerService.getTenantLogoBySubdomain(subdomain).subscribe({
          next: (tenantLogo: string) => {
            this.spinnerService.hide();
            observer.next(tenantLogo ?? Constants.MASTER_TENANT_LOGO);
          },
          error: (error) => {
            this.spinnerService.hide();
            observer.next(Constants.NO_IMAGE);
          }
        });
      } else {
        observer.next(Constants.MASTER_TENANT_LOGO);
      }
    });
  }

  public consoleDebugLog(msg: any, error?: any) {
    if (this.configService.getDebug().enabled) {
      console.log(`${(new Date()).toISOString()} :: ${msg}${error ? ' :: Error details:' : ''}`, error ? error : '');
    }
  }

  public isDevLandscape(): boolean {
    return this.configService.getLandscape().type === LandscapeType.DEVELOPMENT;
  }

  public isQaLandscape(): boolean {
    return this.configService.getLandscape().type === LandscapeType.QA;
  }

  public isProdLandscape(): boolean {
    return this.configService.getLandscape().type === LandscapeType.PRODUCTION;
  }
}
