<div class="wrapper row ms-0">
  <div [hidden]="!sidebarVisible" class="sidebar">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>
  <div class="main-panel col">
    <app-navbar [hidden]="!sidebarVisible"></app-navbar>
    <div class="full-content">
      <h4 *ngIf="numberOfUnpaidInvoices">
        <span class="text-danger">{{'invoices.unpaid_invoices' | translate:{ numberOfUnpaidInvoices } }}</span>
        <button mat-raised-button class="col-12 col-md-auto ms-md-2 ms-0 mt-md-0 mt-2" color="warn" routerLink="/invoices">{{'invoices.pay_invoices' | translate }}</button>
      </h4>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
