import { Pipe, PipeTransform } from '@angular/core';
import { CentralServerService } from 'services/central-server.service';

import { AppDecimalPipe } from './app-decimal.pipe';

@Pipe({ name: 'appDistance' })
export class AppDistancePipe implements PipeTransform {

  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private appDecimalPipe: AppDecimalPipe,
    private centralServerService: CentralServerService
  ) {}

  public transform(value: number): string | null {
    if (value) {
      const user = this.centralServerService.getLoggedUser();
      const result = `${this.appDecimalPipe.transform(value)} ${user.distanceUnit}`;
      return result;
    }
    return '-';
  }
}
