<div class="sidebar-background"></div>
<div class="logo">
  <div class="simple-text logo-mini">
    <a mat-button (click)="toggleSidebar()" href="javascript:void(0)">
      <i class="material-icons">menu</i>
    </a>
  </div>
  <div *ngIf="!sidebarMinimized" class="simple-text ms-3 me-2">
    {{'general.app_name' | translate: { appName } }}
    <div>
      <a routerLink="/release-notes" class="app-version">
        {{'general.version' | translate}} {{version}}
      </a>
    </div>
  </div>
</div>
<div class="logo text-center p-0">
  <div class="simple-text p-1">
    <img class="logo-img" [class.small-logo-img]="sidebarMinimized" [src]="logo" alt="">
  </div>
</div>
<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <img [src]="userImage" />
    </div>
    <div class="user-info">
      <div>
        <span *ngIf="!sidebarMinimized">
          {{user?.name}}
        </span>
        <span *ngIf="!sidebarMinimized">
          {{user?.firstName}}
        </span>
        <span class="mt-2 d-inline-block w-100 text-center">
          <a *ngIf="!sidebarMinimized && isPrepaidBillingFeatureActive && isPrepaidBillingActive; else refreshOnly"
            mat-icon-button class="p-0 me-2" title="{{'general.refresh' | translate}}" (click)="loadUser()">
            <mat-icon>refresh</mat-icon>
            <span>{{'settings.billing.user_balance' | translate}}: {{(user?.balance ?? 0) | appCurrency:loggedUser.currency}}</span>
          </a>
          <ng-template #refreshOnly>
            <a *ngIf="!sidebarMinimized" mat-button class="p-0 me-2" title="{{'general.refresh' | translate}}" (click)="loadUser()">
              <mat-icon>refresh</mat-icon>
              <span>{{'general.refresh' | translate}}</span>
            </a>
          </ng-template>
          <a mat-icon-button class="p-0" title="{{'authentication.sign_out' | translate}}" (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span *ngIf="!sidebarMinimized">{{'authentication.sign_out' | translate}}</span>
          </a>
        </span>
        <a mat-icon-button *ngIf="canUpdateUser && !sidebarMinimized" title="{{'users.edit_profile' | translate}}"
          class="p-0 edit" routerLink="/users/profile">
          <mat-icon>edit</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <ul class="nav nav-content">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngFor="let menuItem of menuItems"
      class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuItem.path]" *ngIf="menuItem.type === 'link'" class="nav-link">
        <i class="material-icons ms-1">{{menuItem.icon}}</i>
        <p>{{'general.menu.' + menuItem.title | translate}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuItem.collapse}}" *ngIf="menuItem.type === 'sub'"
        class="nav-link">
        <i class="material-icons">{{menuItem.icontype}}</i>
        <p>{{'general.menu.' + menuItem.title | translate}}
          <b class="caret"></b>
        </p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuItem.collapse}}" class="collapse" *ngIf="menuItem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuItem.children" class="nav-item">
            <a [routerLink]="[menuItem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
