import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionPeriodicity } from 'types/Subscription';

@Pipe({ name: 'appUserSubscriptionPeriodicityPipe' })
export class AppUserSubscriptionPeriodicityPipe implements PipeTransform {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    public translateService: TranslateService,
  ) {
  }

  public transform(value: SubscriptionPeriodicity): string | null {
    return this.translateService.instant(`subscription.${value}`);
  }
}