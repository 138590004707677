<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card" *ngFor="let releaseNote of releaseNotes">
          <div class="card-header card-header-text card-header-primary">
            <div class="card-text pt-1">
              <h4 class="card-title">{{'general.version' | translate}} {{releaseNote.version}} ({{releaseNote.date | date:'mediumDate'}})</h4>
            </div>
          </div>
          <div class="card-body table-responsive">
            <div class="table table-hover" *ngFor="let componentChange of releaseNote.componentChanges">
              <div>
                <div class="release-notes-row" *ngFor="let change of componentChange.changes">
                  <div>{{change}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
