<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid pb-2 ps-0 pe-0">
    <button mat-icon-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
  </div>
</nav>
