import { Pipe, PipeTransform } from '@angular/core';

import { AppPercentPipe } from './app-percent.pipe';

@Pipe({ name: 'appBatteryPercentage' })
export class AppBatteryPercentagePipe implements PipeTransform {
  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private appPercentPipe: AppPercentPipe,
  ) {}

  public transform(initialPercentage: number, finalPercentage?: number, withEvolution = true): string | null {
    if (initialPercentage || finalPercentage) {
      let formattedMessage = this.appPercentPipe.transform(initialPercentage / 100);
      if (finalPercentage) {
        formattedMessage += ` > ${this.appPercentPipe.transform(finalPercentage / 100)}`;
        if (withEvolution) {
          /* Adding + sign in front of positive values */
          const pct = ((finalPercentage - initialPercentage) > 0) ?
            '+' + this.appPercentPipe.transform((finalPercentage - initialPercentage) / 100) :
            this.appPercentPipe.transform((finalPercentage - initialPercentage) / 100);
          formattedMessage += ` (${pct})`;
        }
      }
      return formattedMessage;
    }
    return `-`;
  }
}
